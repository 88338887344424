
import AllRoutes from './Allroutes/AllRoutes';
import './App.css';

function App() {
  return (
    <div className="App">
      {/* <h1>Hello world .....</h1> */}
      <AllRoutes/>
    </div>
  );
}

export default App;
